import React from 'react';
import AboutUs from '../pages/AboutUs';
import IndustriesPage from '../pages/industries';
import Portfolio from './portfolio';
import digitalSpeakers from '../assets/digitalspeakers.png';
import AnimatedText from '../pages/AnimatedText';
import ContactUs from '../pages/ContactUs';
const Landing = () => {
  return (
    <>
      {/* Landing Section */}
      <div>
      <section className="min-h-screen bg-gray-900 flex flex-col items-center justify-center relative pt-20" id="home">
        {/* Background Accent Circles */}
        <div className="absolute top-20 left-0 w-96 h-96 bg-gradient-to-br from-pink-400 to-purple-600 rounded-full opacity-30 blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-tl from-blue-400 to-indigo-600 rounded-full opacity-30 blur-3xl"></div>

        {/* Main Content */}
        <div className="relative z-10 text-center px-6 mb-8">
          <h1 className="text-5xl md:text-7xl font-extrabold text-white mb-6 tracking-tight leading-tight">
            Transform Your <span className="text-indigo-400">Digital Presence</span>
          </h1>
          <p className="text-lg md:text-2xl text-gray-300 mb-10 max-w-xl mx-auto">
            Elevate your brand with strategic digital marketing solutions that drive real results.
          </p>
          
          {/* Animated Button */}
          <a
            href="https://calendly.com/ejazafeef/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-indigo-500 text-white text-lg font-semibold py-3 px-8 rounded-full transition-transform transform hover:scale-105 shadow-lg hover:shadow-xl duration-300"
          >
            Get Started
          </a>

        </div>
        {/* Image - updated positioning and sizing */}
        <div className="w-[300px] md:w-[370px] md:absolute md:bottom-0 md:right-0 pointer-events-none">
    <img 
      src={digitalSpeakers} 
      alt="Digital Marketing Speaker" 
      className="w-full h-auto"
      style={{ opacity: 0.9}}
    />
          </div>
      </section>
      </div>

      <AboutUs />

      <IndustriesPage />

      <AnimatedText />
      
      <Portfolio />

      <ContactUs />
    </>
  );
};

export default Landing;
