import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import art1 from '../assets/art1.jpg';
import art2 from '../assets/art2.jpg';
import art3 from '../assets/art3.jpg';

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {
  const imagesRef = useRef([]);

  useEffect(() => {
    const images = imagesRef.current;
    
    // Create media match for desktop
    const mediaQuery = window.matchMedia("(min-width: 1024px)");

    if (mediaQuery.matches) {
      // Desktop animations - outward effect
      gsap.set(images[0], { x: 0, opacity: 0 }); // Left image
      gsap.set(images[1], { opacity: 0 }); // Center image
      gsap.set(images[2], { x: 0, opacity: 0 }); // Right image

      // Center image animation
      gsap.to(images[1], {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: images[1],
          start: "top center+=100",
          toggleActions: "play none none reverse"
        }
      });

      // Side images animation
      gsap.to(images[0], {
        x: -50,
        opacity: 1,
        duration: 1.5,
        delay: 0.2,
        scrollTrigger: {
          trigger: images[0],
          start: "top center+=100",
          toggleActions: "play none none reverse"
        }
      });

      gsap.to(images[2], {
        x: 50,
        opacity: 1,
        duration: 1.5,
        delay: 0.2,
        scrollTrigger: {
          trigger: images[2],
          start: "top center+=100",
          toggleActions: "play none none reverse"
        }
      });
    } else {
      // Enhanced mobile animations
      images.forEach((image, index) => {
        // Initial state
        gsap.set(image, {
          opacity: 0,
          y: 30 // Start slightly below final position
        });

        // Animation
        gsap.to(image, {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.3,
          ease: "power2.out",
          scrollTrigger: {
            trigger: image,
            start: "top center+=150",
            toggleActions: "play none none reverse"
          }
        });

        // Optional hover effect for mobile
        gsap.to(image, {
          scale: 1,
          duration: 0.3,
          paused: true,
          ease: "power2.out"
        });
      });
    }
  }, []);

  return (
    <div className="min-h-screen bg-[#f8f9ff] py-12 sm:py-24 px-4 sm:px-6 overflow-x-hidden">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto mb-12 sm:mb-20 text-center">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 mb-8 px-4">
          <span className="block sm:inline">Enhance Your</span>{' '}
          <span className="block sm:inline">Digital <span className="text-indigo-600">Impact</span></span>
        </h1>
      </div>

      {/* Gallery Grid */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-12 px-0 sm:px-4">
        {/* Project 1 */}
        <div 
          className="group cursor-pointer"
          ref={el => imagesRef.current[0] = el}
        >
          <div className="relative overflow-hidden rounded-2xl sm:rounded-3xl mb-6">
            <div className="aspect-[4/5] w-full">
              <img 
                src={art1} 
                alt="Digital Marketing Project 1" 
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
              />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 bg-gradient-to-t from-black/80 to-transparent">
              
              <h3 className="text-white text-center text-xl sm:text-2xl font-bold">Digital Evolution</h3>
            </div>
          </div>
        </div>

        {/* Project 2 */}
        <div 
          className="group cursor-pointer"
          ref={el => imagesRef.current[1] = el}
        >
          <div className="relative overflow-hidden rounded-2xl sm:rounded-3xl mb-6">
            <div className="aspect-[4/5] w-full">
              <img 
                src={art2} 
                alt="Digital Marketing Project 2" 
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
              />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 bg-gradient-to-t from-black/80 to-transparent">
             
              <h3 className="text-white text-center text-xl sm:text-2xl font-bold">Community Building</h3>
            </div>
          </div>
        </div>

        {/* Project 3 */}
        <div 
          className="group cursor-pointer"
          ref={el => imagesRef.current[2] = el}
        >
          <div className="relative overflow-hidden rounded-2xl sm:rounded-3xl mb-6">
            <div className="aspect-[4/5] w-full">
              <img 
                src={art3} 
                alt="Digital Marketing Project 3" 
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
              />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 bg-gradient-to-t from-black/80 to-transparent">
              
              <h3 className="text-white text-center text-xl sm:text-2xl font-bold">Digital Experience</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;