import React from 'react';
import { Helmet } from 'react-helmet';
import digitalSpeaker from '../assets/digitalspeaker.png';

const About = () => {
  return (
    <div className="flex flex-col-reverse sm:flex-row items-center max-w-screen-xl mx-auto mt-10">
      {/* Structured Data */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Pixel Link",
            "url": "https://pixellinkdigital.com",
            "logo": "https://pixellinkdigital.com//public/logo4.png",
            "description": "We are a US-based Digital Marketing Agency with a local presence in the UAE. We specialize in delivering innovative digital marketing solutions to empower businesses.",
            "contactPoint": [
              {
                "@type": "ContactPoint",
                "telephone": "+1 (929) 218-9460",
                "contactType": "Customer Service",
                "areaServed": ["US", "UAE"],
                "availableLanguage": ["English", "Arabic"]
              }
            ]
          })}
        </script>
      </Helmet>

      {/* Your Page Content */}
      <div className="w-full sm:w-1/2 p-4">
        <div className="image object-center text-center">
          <img
            src={digitalSpeaker}
            alt="Digital Marketing Speaker"
            className="w-3/4 h-auto mx-auto"
            style={{ opacity: 0.9 }}
          />
        </div>
      </div>
      <div className="w-full sm:w-1/2 p-4">
        <div className="text">
          <span className="text-black border-b-2 border-indigo-600 uppercase">About us</span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl">
            About <span className="text-indigo-600">Our Company</span>
          </h2>
          <p className="text-gray-700">
            We are a US-based Digital Marketing Agency with local presence in the UAE. We specialize in delivering innovative digital marketing solutions to empower businesses. Our mission is to provide scalable, efficient marketing strategies that drive business growth and brand awareness across industries. We create compelling digital campaigns that solve real-world marketing challenges and deliver measurable ROI. Whether you need a dedicated marketing expert or an entire team, we can provide the right talent to meet your specific marketing objectives.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
