import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AnimatedText = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const textRef1 = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const text1 = textRef1.current;

    // Initial state - starting with larger size
    gsap.set(text1, {
      fontSize: "3rem", // Bigger initial size
      opacity: 0,
    });

    // Animation timeline
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top center",
        end: "+=100%",
        scrub: 1.5,
        markers: false,
      }
    })
    .to(text1, {
      fontSize: "clamp(4.5rem, 7vw, 6.5rem)", // Bigger final size
      opacity: 1,
      ease: "power2.out",
      duration: 1
    });

  }, []);

  useLayoutEffect(() => {
    const scrollingText = gsap.to(".scrolling-text", {
      xPercent: -100,
      repeat: -1,
      duration: 15, // Faster duration
      ease: "none", // Linear movement for smoother scroll
    });

    // Optional: Pause on hover
    const elements = document.querySelectorAll(".scrolling-container");
    elements.forEach(elem => {
      elem.addEventListener("mouseenter", () => scrollingText.pause());
      elem.addEventListener("mouseleave", () => scrollingText.play());
    });

    return () => {
      scrollingText.kill();
    };
  }, []);

  return (
    <section 
      ref={sectionRef}
      className="min-h-screen bg-white relative py-20 px-4 sm:px-6 overflow-hidden"
    >
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-purple-50 opacity-70 -z-10" />
      
      {/* Content container */}
      <div 
        ref={containerRef}
        className="relative max-w-7xl mx-auto flex flex-col items-center justify-center min-h-[80vh] z-10"
      >
        <div className="text-center w-full max-w-4xl mx-auto space-y-8 sm:space-y-12 relative z-20">
          {/* Main heading - with smooth scaling animation */}
          <div className="px-4 sm:px-0">
            <div className="w-full max-w-[90%] sm:max-w-3xl mx-auto">
              <h1 
                ref={textRef1}
                className="font-bold leading-[1.2] tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 mx-auto pb-2"
                style={{ 
                  fontSize: '3rem'
                }}
              >
                Transform Your<br />
                Digital Presence
              </h1>
            </div>
          </div>
          
          {/* Subheading - static */}
          <div className="px-5 sm:px-0 relative z-20 mt-16 sm:mt-20">
            <p className="font-semibold text-gray-800 mx-auto text-[1.8rem] sm:text-[2.4rem]">
              <span className="hidden sm:inline">Drive Growth • Engage Audiences • Convert Leads</span>
              <span className="sm:hidden">Drive Growth<br/>Engage Audiences<br/>Convert Leads</span>
            </p>
          </div>
          
         
        </div>

        {/* Decorative elements */}
        <div className="absolute top-1/3 -left-20 w-72 h-72 bg-blue-100/50 rounded-full blur-3xl -z-5" />
        <div className="absolute bottom-1/3 -right-20 w-72 h-72 bg-purple-100/50 rounded-full blur-3xl -z-5" />
      </div>

      {/* Modern scrolling text */}
      <div className="absolute bottom-0 left-0 right-0 overflow-hidden whitespace-nowrap py-6 bg-gradient-to-r from-blue-100/80 via-indigo-100/80 to-purple-100/80 backdrop-blur-sm scrolling-container">
        <div className="inline-block scrolling-text">
          <span className="inline-block px-4 text-xl sm:text-2xl font-medium tracking-wide text-gray-700">
            Digital Innovation • SEO Excellence • Content Strategy • Social Media Growth • Web Development • Brand Building •&nbsp;
          </span>
        </div>
        <div className="inline-block scrolling-text">
          <span className="inline-block px-4 text-xl sm:text-2xl font-medium tracking-wide text-gray-700">
            Digital Innovation • SEO Excellence • Content Strategy • Social Media Growth • Web Development • Brand Building •&nbsp;
          </span>
        </div>
      </div>
    </section>
  );
};

export default AnimatedText;