import React, { useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    companyName: '',
    location: '',
    message: '',
    service: 'Web Design & Development'
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('+91');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: formData.name,
      email: formData.email,
      contactNumber: selectedCountry + formData.contactNumber,
      companyName: formData.companyName,
      location: formData.location,
      message: formData.message,
      service: formData.service
    };

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwYgWZw1z-4nMHwX1cFPBsDvyRZeP9i36HGbUU7M75htaTEiYquwnLdoRskmf9M7fFkPg/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response) {
        setIsSubmitted(true);
        setError(null);
        setFormData({
          name: '',
          email: '',
          contactNumber: '',
          companyName: '',
          location: '',
          message: '',
          service: 'Web Design & Development'
        });
      } else {
        setError('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Error submitting form');
    }
  };

  return (
    <div className="min-h-screen relative bg-[#0f1117] text-white py-12 px-4 overflow-hidden">
      {/* Modern Background Elements */}
      <div className="absolute inset-0 z-0">
        {/* Gradient Orbs */}
        <div className="absolute top-0 left-0 w-[500px] h-[500px] bg-gradient-to-r from-purple-500/30 to-blue-500/30 rounded-full blur-[80px] -translate-x-1/2 -translate-y-1/2"></div>
        <div className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-gradient-to-l from-emerald-500/30 to-cyan-500/30 rounded-full blur-[80px] translate-x-1/2 translate-y-1/2"></div>
        {/* Additional decorative elements */}
        <div className="absolute top-1/2 left-1/2 w-[800px] h-[800px] bg-gradient-to-tr from-indigo-500/20 to-pink-500/20 rounded-full blur-[100px] -translate-x-1/2 -translate-y-1/2"></div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto relative z-10 relative mt-20">
        {/* Main Container - Grid Layout */}
        <div className="grid md:grid-cols-2 gap-8 items-start">
          {/* What We Do Section - Left Side */}
          <div className="mb-12 md:mb-0 md:sticky md:top-20 backdrop-blur-lg bg-white/5 p-8 rounded-2xl border border-white/10">
            <h2 className="relative inline-block text-2xl md:text-3xl font-bold mb-8">
              <span className="bg-gradient-to-r from-[#4ade80] to-emerald-400 bg-clip-text text-transparent">
                What We Do
              </span>
              {/* Modern Underline Effect */}
              <div className="absolute -bottom-2 left-0 w-1/3 h-1 bg-gradient-to-r from-[#4ade80] to-emerald-400 rounded-full"></div>
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed mb-8">
              As a website designer we provide affordable website design services to our clients all over the world with best services. Our services include; small business website design services
            </p>

            {/* Services List */}
            <div className="space-y-4">
              {[
                'Social Media Marketing',
                'Website Design & Development',
                'Search Engine Optimization (SEO)',
                'Content Marketing Strategy',
                'Pay-Per-Click Advertising (PPC)',
                'Email Marketing Campaigns'
              ].map((service, index) => (
                <div 
                  key={index} 
                  className="border-b border-white/10 py-4 hover:bg-white/5 transition-colors rounded-lg px-4"
                >
                  <h3 className="text-lg text-white">{service}</h3>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Form - Right Side */}
          <div className="bg-[#151922] p-8 rounded-lg shadow-xl border border-[#4ade80]/20">
            <h2 className="text-3xl font-bold mb-8">Drop Us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name */}
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name*"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-white/5 border-b border-white/10 p-3 text-white placeholder-gray-400 focus:border-[#4ade80] focus:outline-none rounded-lg transition-colors"
                  required
                />
              </div>

              {/* Phone Number with Country Code */}
              <div className="flex gap-4">
                <div className="w-1/4">
                  <select
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    className="w-full bg-white/5 border-b border-white/10 p-3 text-gray-400 focus:border-[#4ade80] focus:outline-none rounded-lg transition-colors"
                  >
                    <option value="+971">UAE +971</option>
                    <option value="+1">US +1</option>
                    <option value="+44">GB +44</option>
                  </select>
                </div>
                <div className="w-3/4">
                  <input
                    type="tel"
                    name="contactNumber"
                    placeholder="Phone No*"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    className="w-full bg-white/5 border-b border-white/10 p-3 text-white placeholder-gray-400 focus:border-[#4ade80] focus:outline-none rounded-lg transition-colors"
                    required
                  />
                </div>
              </div>

              {/* Email */}
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-white/5 border-b border-white/10 p-3 text-white placeholder-gray-400 focus:border-[#4ade80] focus:outline-none rounded-lg transition-colors"
                  required
                />
              </div>



              {/* Message */}
              <div>
  <textarea
    name="message"
    placeholder="Message*"
    value={formData.message}
    onChange={handleChange}
    rows="4"
    className="w-full bg-white/5 border-b border-white/10 p-3 text-white placeholder-gray-400 focus:border-[#4ade80] focus:outline-none rounded-lg transition-colors resize-none"
    required
  />
</div>


              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#4ade80] to-emerald-400 text-black py-4 rounded-xl font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center gap-2 shadow-lg"
              >
                Connect With PixelLink Today <FaArrowUp className="transform rotate-45" />
              </button>

              {/* Status Messages with updated styling */}
              {isSubmitted && (
                <div className="text-[#4ade80] text-center mt-4 bg-[#4ade80]/10 p-4 rounded-lg">
                  Thank you for your message! We will get back to you soon.
                </div>
              )}
              {error && (
                <div className="text-red-500 text-center mt-4 bg-red-500/10 p-4 rounded-lg">
                  {error}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
