import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiChevronDown, FiMenu, FiX } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faPenToSquare,
  faCode,
  faHashtag,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logos.png';

const Header = () => {
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const navigate = useNavigate();

  let servicesTimeout;
  
  const services = [
    { name: 'SEO Services', icon: faMagnifyingGlass, slug: 'seo' },
    { name: 'Content Marketing', icon: faPenToSquare, slug: 'content' },
    { name: 'Website Development', icon: faCode, slug: 'web-dev' },
    { name: 'Social Media Marketing', icon: faHashtag, slug: 'social' },
  ];

  const handleServiceClick = (index) => {
    setIsServicesMenuOpen(false);
    setIsMobileMenuOpen(false);
    navigate('/industries');
    setTimeout(() => {
      const element = document.getElementById(services[index].slug);
      if (element) {
        const headerHeight = 80; // Base header height
        
        // Adjust offset based on index
        let additionalOffset = 0;
        switch(index) {
          case 0: // SEO Services
            additionalOffset = 0;
            break;
          case 1: // Content Marketing
            additionalOffset = -250;
            break;
          case 2: // Website Development
            additionalOffset = -350;
            break;
          case 3: // Social Media Marketing
            additionalOffset = -500;
            break;
          default:
            additionalOffset = 0;
        }

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerHeight + additionalOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });

        // Trigger the selection in Industries component
        window.dispatchEvent(new CustomEvent('selectIndustry', { 
          detail: { index } 
        }));
      }
    }, 100);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxOpacityScroll = 300;
      const opacity = Math.min(scrollTop / maxOpacityScroll + 0.9, 1);
      setScrollOpacity(opacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Services menu handlers with delay
  const handleServicesMouseEnter = () => {
    clearTimeout(servicesTimeout);
    setIsServicesMenuOpen(true);
  };

  const handleServicesMouseLeave = () => {
    servicesTimeout = setTimeout(() => {
      setIsServicesMenuOpen(false);
    }, 200); // 200ms delay before closing
  };

  
  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 bg-white transition-opacity duration-500 ease-in-out shadow-md ${
        scrollOpacity < 1 ? 'opacity-90' : 'opacity-100'
      }`}
    >
      <nav className="mx-auto flex max-w-6xl items-center justify-between p-4 lg:px-6" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            
            <img
 className="max-h-[62px] w-auto"
 src={logo}
 alt="Company Logo"
/>



          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden flex items-center w-full">
  

  <a
            href="https://calendly.com/ejazafeef/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-auto px-4 py-1 bg-indigo-600 text-white rounded-full shadow-md hover:bg-indigo-500 mr-6"
          >
            Let&aposs chat
          </a>
</div>

          

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center space-x-8">
          <div
            className="relative group"
            onMouseEnter={handleServicesMouseEnter}
            onMouseLeave={handleServicesMouseLeave}
          >
            <button className="flex items-center text-gray-800 font-medium hover:text-indigo-600">
              Services
              <FiChevronDown className="ml-1" />
            </button>
            {isServicesMenuOpen && (
              <div
                className="absolute top-full mt-2 w-64 rounded-lg bg-white shadow-lg z-50 transition-all duration-300 ease-in-out"
                onMouseEnter={handleServicesMouseEnter}
                onMouseLeave={handleServicesMouseLeave}
              >
                <ul className="py-4">
                  {services.map((service, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleServiceClick(index)}
                        className="w-full px-4 py-2 text-gray-700 flex items-center hover:bg-gray-100"
                      >
                        <FontAwesomeIcon
                          icon={service.icon}
                          className="text-indigo-600 mr-2"
                        />
                        {service.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <Link to="/about-us" className="text-gray-800 font-medium hover:text-indigo-600">
            About
          </Link>
          <Link to="/contact" className="text-gray-800 font-medium hover:text-indigo-600">
            Contact
          </Link>
          <Link
            to="/contact"
            className="px-6 py-2 bg-indigo-600 text-white rounded-full shadow-md hover:bg-indigo-500"
          >
            Let’s Chat
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="lg:hidden text-gray-900 focus:outline-none"
        >
          {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden absolute top-full left-0 w-full bg-white shadow-lg z-50">
          <ul className="py-4">
            <li>
              <Link
                to="/about-us"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Contact
              </Link>
            </li>
            {services.map((service, index) => (
              <li key={index}>
                <button
                  onClick={() => handleServiceClick(index)}
                  className="w-full px-4 py-2 text-gray-700 flex items-center hover:bg-gray-100"
                >
                  <FontAwesomeIcon
                    icon={service.icon}
                    className="mr-2 text-indigo-600"
                  />
                  {service.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
