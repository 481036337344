import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPenToSquare, faCode, faHashtag } from '@fortawesome/free-solid-svg-icons'; // Updated icons

const industries = [
  { 
    name: 'SEO Services', 
    slug: 'seo', 
    icon: faMagnifyingGlass,
    description: 'Boost your online visibility and rank higher in search engine results with our comprehensive SEO strategies.', 
    services: ['Keyword Research', 'On-Page SEO', 'Voice SEO', 'Link Building', 'Local SEO'] 
  },
  { 
    name: 'Content Marketing', 
    slug: 'content', 
    icon: faPenToSquare,
    description: 'Create compelling content that engages your audience and drives conversions.', 
    services: ['Blog Writing', 'Copywriting', 'Content Strategy', 'Email Marketing', 'Content Distribution'] 
  },
  { 
    name: 'Website Development', 
    slug: 'web-dev', 
    icon: faCode,
    description: 'Create stunning, responsive websites that deliver exceptional user experiences and drive business growth.', 
    services: ['Custom Website Design', 'E-commerce Development', 'Website Maintenance', 'Mobile Optimization', 'Performance Optimization'] 
  },
  { 
    name: 'Social Media Marketing', 
    slug: 'social', 
    icon: faHashtag,
    description: 'Build your brand presence and engage with your audience across social media platforms.', 
    services: ['Social Strategy', 'Content Creation', 'Community Management', 'Influencer Marketing'] 
  }
];

const IndustriesPage = () => {
  const [selectedIndustry, setSelectedIndustry] = useState(0);

  useEffect(() => {
    const handleServiceSelection = (event) => {
      setSelectedIndustry(event.detail.index);
    };

    window.addEventListener('selectIndustry', handleServiceSelection);
    return () => {
      window.removeEventListener('selectIndustry', handleServiceSelection);
    };
  }, []);

  const handleSelectIndustry = (index) => {
    setSelectedIndustry(selectedIndustry === index ? null : index);
  };
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white flex flex-col md:flex-row overflow-hidden">
      {/* Sidebar: List of Industries */}
      <div className="w-full md:w-1/4 bg-gray-900 flex flex-col justify-center items-center border-b md:border-b-0 md:border-r border-gray-700 p-6">
        <h1 className="text-3xl md:text-4xl font-extrabold mb-8 text-gray-200">Services</h1>
        <ul className="space-y-8">
          {industries.map((industry, index) => (
            <li 
              key={index}
              id={industry.slug}
              onClick={() => handleSelectIndustry(index)}
              className={`cursor-pointer py-6 px-8 rounded-lg flex flex-col items-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-indigo-600 ${
                selectedIndustry === index ? 'bg-indigo-600 scale-105 shadow-xl' : 'bg-gray-800'
              }`}
            >
              <FontAwesomeIcon icon={industry.icon} className="text-4xl text-gray-200 mb-4 transition duration-300 ease-in-out transform hover:scale-110" />
              <span className="text-lg font-semibold text-gray-100">{industry.name}</span>

              {/* Display Details Under the Selected Industry in Mobile */}
              {selectedIndustry === index && (
                <div className="block md:hidden bg-white text-black p-4 mt-4 rounded-lg shadow-xl slide-down">
                  <div className="flex items-center mb-4">
                    <FontAwesomeIcon icon={industry.icon} className="text-4xl text-indigo-600 mr-4" />
                    <h2 className="text-2xl font-bold text-gray-800">{industry.name}</h2>
                  </div>
                  <p className="mb-4 text-sm font-light text-gray-700 leading-relaxed">{industry.description}</p>
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">Services We Provide:</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    {industry.services.map((service, serviceIndex) => (
                      <li key={serviceIndex}>{service}</li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Content: Expanded Industry Details for Desktop */}
      <div className="hidden md:flex w-3/4 p-12 items-center justify-center relative">
        {selectedIndustry !== null && (
          <div className="bg-white text-black p-6 md:p-10 rounded-xl shadow-2xl w-full max-w-3xl relative transition-all duration-500 ease-in-out transform hover:scale-105 slide-down">
            {/* Background Design Element */}
            <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-gray-300 rounded-xl opacity-10 z-0"></div>

            <div className="flex items-center mb-6 relative z-10">
              <FontAwesomeIcon icon={industries[selectedIndustry].icon} className="text-4xl text-indigo-600 mr-6" />
              <h2 className="text-4xl font-bold text-gray-800">{industries[selectedIndustry].name}</h2>
            </div>

            <p className="mb-6 text-lg font-light text-gray-700 relative z-10 leading-relaxed">{industries[selectedIndustry].description}</p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-4 relative z-10">Services We Provide:</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700 relative z-10">
              {industries[selectedIndustry].services.map((service, serviceIndex) => (
                <li key={serviceIndex}>{service}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndustriesPage;
